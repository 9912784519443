<template>
  <MyAccountCheckAuthLoggedIn>
    <MyAccountProfileEdit />
  </MyAccountCheckAuthLoggedIn>
</template>

<script setup lang="ts">
const { url } = useDomainHost()

const { t } = useI18n()

const { store } = useDomainState()

useServerSeoMeta(
  useOgMeta(
    url + '/my-account/edit',
    t('edit_profile'),
    store.value?.about,
    store.value?.thumb
  )
)

useHead({
  title: t('edit_profile'),
  meta: [
    { hid: 'description', name: 'description', content: store.value?.about }
  ],
  link: [useSeoCanonical(url + '/my-account/edit')],
  script: [
    useSeoBreadcrumb([
      useSeoBreadcrumbItem(1, t('home'), url),
      useSeoBreadcrumbItem(2, t('profile'), url + '/my-account'),
      useSeoBreadcrumbItem(3, t('edit_profile'), url + '/my-account/edit')
    ])
  ]
})
</script>
